<template>
    <div class="card">
        <div class="card-body">
            <h4 class="card-title">Latest News</h4>
            <h6 class="card-subtitle">A list of Scripture Union news and announcements</h6>
            <div class="actions">
                <router-link to="/news/add">
                    <i class="zmdi zmdi-plus actions__item"></i>
                </router-link>
            </div>
        </div>
        <div v-if="isLoading">Loading...</div>
        <div v-else-if="news.length > 0" class="listview listview--bordered">
            <div class="listview__item" v-for="(post, i) in news" :key="i">
                <img class="listview__img_news" alt="" @click="view(post.id)" :src="post.photo" v-if="post.photo != ''">
                <i class="avatar-char bg-light-blue" v-else></i>
                <div class="listview__content">
                    <div class="listview__heading" @click="view(post.id)">{{ post.title }}</div>
                    <p>{{ post.content }}</p>
                </div>
                <div class="actions listview__actions">
                    <i class="actions__item zmdi zmdi-eye" @click="view(post.id)"></i>
                </div>
            </div>
        </div>
        <div class="notfound" v-else>No news items found</div>
    </div>
</template>

<script>
export default {
    data(){
        return {
            news: [],
            isLoading: false,
        }
    },
    created(){
        this.loadNews()
    },
    methods: {
        async loadNews(){
            try {
                this.isLoading = true
                const snap = await this.$db.collection("news").orderBy("createdAt", "desc").limit(5).get()
                snap.forEach(doc => {
                    this.news.push({ id: doc.id, ...doc.data() })
                });
                this.isLoading = false
            } catch (error) {
                this.isLoading = false
            }
        },
        view(id){
            this.$router.push("/news/n/" + id)
        },
    }
}
</script>


<style scoped>
.listview__img_news {
    width: 40.50px;
    height: 40.50px;
    object-fit: cover;
    border-radius: 50%;
    margin-right: 1.2rem;
}
.listview__heading {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap
}
</style>